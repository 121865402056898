import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About`}</h2>
    <h5>{`Ready to change the world`}</h5>
    <p>{`Howdy! I am Ezekiel Quintanilla`}</p>
    <p>{`I graduated from the University of Texas at Austin 2014 with a BA in History but have since found my calling in software development. Originally self-taught, I earned my first role in technology as a Software QA engineer in 2016. While working full time, I've earned an associates degree in Computer Science in 2020 and am now enrolled at Georgia Tech pursuing a masters degree.`}</p>
    <p>{`I currently specialize in full stack enterprise conversational AI applications in the contact center industry but am ready and willing to tackle any opportunity.`}</p>
    <p>{`I love learning, adapting, overcoming obstacles, and am never one to back down from a challenge. Connect with me at the links below!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      