import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`GitHub Projects`}</h2>
    <ProjectCard title="Discrete Time Event Simulator" link="https://github.com/equintanilla20/CS3360/tree/main/pa2" bg="linear-gradient(to right, #191414 80%, #6e5494 100%)" mdxType="ProjectCard">
Simulation of the scheduling and running of processes on a CPU with a priority queue, arrival/departure handlers for processes, and variable lambda and average service time values. #C++ #VSCode
    </ProjectCard>
    <ProjectCard title="Sudoku Solver" link="https://github.com/equintanilla20/javaOOP/tree/master/JavaSudokuFinal" bg="linear-gradient(to right, #191414 80%, #6e5494 100%)" mdxType="ProjectCard">
An application using a backtracking algorithm to solve Sudoku puzzles #Java #Eclipse
    </ProjectCard>
    <ProjectCard title="Jammming" link="https://equintanilla20.github.io/jammming_v2" bg="linear-gradient(to right, #191414 80%, #1DB954 99%)" mdxType="ProjectCard">
A webapp used to build Spotify playlists and save them to the user's account - Made for the Codecademy Build Front End Webapps course & certification (Link to source code under Get In Touch below) #Javascript #React #Node #VSCode
    </ProjectCard>
    <ProjectCard title="Ravenous" link="https://equintanilla20.github.io/ravenous" bg="linear-gradient(to right, #191414 80%, #C41200 100%)" mdxType="ProjectCard">
A webapp for searching cuisines in a given city - Made for the Codecademy Build Front End Webapps course & certification (Link to source code under Get In Touch below) #Javascript #React #Node #VSCode
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      