import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`Get In Touch`}</h3>
    <p>{`Send me an `}<a href="sendto:equintanilla20@gmail.com" title="Click to open in a new tab" target="_blank">{`email`}</a>{`.`}</p>
    <p>{`Connect on `}<a href="https://www.linkedin.com/in/equintanilla20/" title="Click to open in a new tab" target="_blank">{`LinkedIn`}</a>{`.`}</p>
    <p>{`Check out my `}<a href="https://github.com/equintanilla20" title="Click to open in a new tab" target="_blank">{`GitHub`}</a>{`.`}</p>
    <p>{`Jammming code `}<a href="https://github.com/equintanilla20/jammming_v2" title="Click to open in a new tab" target="_blank">{`repository`}</a>{`.`}</p>
    <p>{`Ravenous code `}<a href="https://github.com/equintanilla20/ravenous" title="Click to open in a new tab" target="_blank">{`repository`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      